/**
 * 我的邀约
 */
export default [
  {
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/invite/index.vue'),
    meta: {
      title: '我的邀约',
      icon: 'menu-invite',
      isMenu: "/invite",
      keepAlive: true,
      Primary: true,
    },
  },
  {
    path: '/invite/Exchange',
    name: 'inviteExchange',
    component: () => import('@/views/invite/Exchange/index.vue'),
    meta: {
      title: '兑换应用',
      icon: '',
      keepAlive: true,
      ParentMenu: "/invite"//父级菜单路径 显示侧边栏
    }
  },
]