
import axios from 'axios'
import { errorMessage } from "@/utils/message.js";
import { setStore } from '@/utils/localStorage'
import { setToken, getToken } from "@/utils/auth";
import store from '../store'
import { baseApi } from '@/config'
// let baseURL = 'http://192.168.88.169:8080'
// let baseURL = 'http://192.168.88.232:8081'
// let baseURL = 'http://test.y-cat.com'
// 创建axios实例
const service = axios.create({
  baseURL: baseApi, // api的base_url
  // baseURL: baseURL, // api的base_url
  timeout: 50000 // 请求超时时间
})
//请求拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers.common['Authorization'] = getToken()// 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // 路由校验加入请求头
    if (config.isfunction_verify) {
      config.headers.common['Resources-Routing'] = config.functionValue
    }
    // 布控点校验加入请求头
    if (config.point_verify) {
      config.headers.common['Control-Point-Mark'] = config.pointValue
    }
    const headers = config.headers
    if (headers['content-type'] === 'application/json;charset=utf-8') {
      return config.data
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    const code = response.data.code
    // token 有更新修改本地
    if ((response.data.token ?? '') !== '') {
      store.commit("SET_TOKEN", response.data.token);
      setToken(response.data.token);
    }
    // 菜单更新清空后刷新
    if (response.data.menu_update) {
      store.commit('setSidebarMenu', [])
      setStore({
        name: 'SidebarMenu',
        content: [],
      })
      setTimeout(() => {
        location.reload();
      }, 500);
    }
    if (code !== 200) {
      let tokenArr = [20001, 20002, 20003, 20004]//token error code
      if (tokenArr.includes(code)) {
        // 判断token错误
        FedLogOut(response.data.msg)
        // return response
      } else if (code == 30006) {
        FedLogOut(response.data.msg)
      }
      return response
      // return Promise.reject(new Error(response.message || 'Error'))
    } else {
      return response
    }
  },
  error => {
    console.log('err: ', error) // for debug
    if (error.response != null) {
      if (error.response.status === 500) {
        FedLogOut('内部错误');
        return Promise.reject(error)
      }
      // 401处理
      if (error.response.status === 401) {
        FedLogOut('登陆过期请重新登陆')
        return Promise.reject(error)
      } else {
        // errorMessage(error.response.data.message);
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)
function FedLogOut(val) {
  errorMessage(val);
  // 重置登录页
  store
    .dispatch("FedLogOut")
    .then(() => {
      setTimeout(() => {
        location.reload();
      }, 500);
    })
}
export default service
