// token操作
import Cookies from 'vue-cookies'
const TokenKey = 'portal-Token'
const changedKey = 'changed'

// 获取
export function getToken() {
  return Cookies.get(TokenKey)
}
export function getChanged() {
  return Cookies.get(changedKey)
}

// 设置
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function setChanged(changed) {
  return Cookies.set(changedKey, changed)
}
// 删除
export function removeToken() {
  return Cookies.remove(TokenKey)
}