// 全局提示样式
import { ElMessage } from "element-plus";
import error from "@/components/customMessage/error.vue"
import check from "@/components/customMessage/check.vue"
import success from "@/components/customMessage/success.vue"
// import warning from "@/components/customMessage/warning.vue"
// 成功
export function successMessage(val) {
  ElMessage({
    customClass: "success-message",
    icon: success,
    message: val,
    center: true,
  });
}
let checkData = null
// 关闭校验提示
export function checkClose() {
  checkData ? checkData.close() : ''
}
// 校验提示
export function checkMessage(val) {
  // 关闭上一个
  checkData ? checkData.close() : ''
  checkData =
    ElMessage({
      customClass: "check-message",
      message: val && val.length > 0
        ? val.join(", ")
        : "",
      icon: <check></check>,
      center: true,
      duration: 8000
    })
  // 修改关闭按钮高度
  setTimeout(() => {
    let checkheight = document.querySelectorAll('.check-message')
    checkheight.forEach((item) => {
      item.children[0].style.height = `${item.children[1].clientHeight}px`
    })
  }, 0);
}

// 错误
export function errorMessage(val) {
  ElMessage({
    customClass: "error-message",
    icon: error,
    message: val,
    grouping: true,
    center: true,
  });
}

// 警告
export function warningMessage(val) {
  ElMessage({
    customClass: "warning-message",
    icon: error,
    message: val,
    center: true,
  });
}

// 多个提示
export function multipleMessage(res, is = 'err') {
  let tokenArr = [9996, 9997, 10000, 9999, 30005]//提示信息
  let tokenArr2 = [9998]
  if (tokenArr.includes(res.code)) {
    is == 'err' ? errorMessage(res.msg) : warningMessage(res.msg)
  } else if (tokenArr2.includes(res.code)) {
    errorMessage('系统错误请联系管理员!')
  }
}