import router from '@/router'
import store from '@/store'
import { localGet } from '@/utils/localStorage'

// 跳转第一个标签并停止
export function firstRoute(path, val) {
  let tags = store.getters.layout.tags
  // let tpye = null //1删除路由判断 2删除跳转后不触发搜索 3菜单第一个不刷新
  store.commit('setRouterType', val)
  if (path.path == tags[0].path) {
    // store.commit('setRouterType', 1)
    // 需要刷新
    if (val == 1 && tags.length <= 1) {
      setTimeout(() => {
        router.push({
          path: "/layout",
        });
      }, 300);
    } else {
      router.push({
        path: `${getFirstRoute().path}`,
      });
    }
  } else {
    let SidebarMenu = localGet({
      name: 'SidebarMenu'
    })
    store.dispatch("removeTag", path);
    if (SidebarMenu.filter((value) => value.path == getFirstRoute().path) && SidebarMenu.filter((value) => value.path == getFirstRoute().path).length > 0) {
      // store.commit('setRouterType', 3)
      // 校验菜单接口
      router.push({
        path: `${getFirstRoute().path}`,
      });
    } else {
      // store.commit('setRouterType', 2)
      store.dispatch("removeTag", path);
      let query = getFirstRoute().query
      // 匹配对应的路由权限
      router.push({
        path: `${getFirstRoute().fullPath}`,
        query: query
      });
    }
  }
}
// 获取第一个路由
function getFirstRoute() {
  return store.getters.layout.tags && store.getters.layout.tags.length > 0 ? store.getters.layout.tags[0] : '/Layout'
}
export function isFirstRoute(to) {
  console.log('to', to);
  return store.getters.layout.tags[0].path == to.path
}
// 获取校验路由
export function getRouter(route) {
  const { path } = route
  const num = path.substring(path.lastIndexOf('/') + 1)
  if (/^[0-9]*[1-9][0-9]*$/g.test(num)) {
    let name = []
    let arr = path.split('/')
    for (let index = 0; index < arr.length; index++) {
      if (arr[index] && !/^[0-9]*[1-9][0-9]*$/g.test(arr[index])) {
        name.push(arr[index])
      }
    }
    return `/${name.join('/')}`
    // return `/${path.split('/')[1]}/${path.split('/')[2]}`
  } else {
    return `/${num}`
  }
}