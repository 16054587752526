import { removeToken } from '@/utils/auth'
import { setStore, localGet, localRemove } from '@/utils/localStorage'
import { login, loginout, menu_update, } from '@/api/login'
import { function_verify, control_point_verify } from '@/api/verify'
import { permissionEncrypt } from "@/utils/Crypto.js";
import { errorMessage } from "@/utils/message.js";
import router from '@/router'

const user = {
  state: {
    token: localGet({
      name: 'token'
    }) || '',//token
    // token: getToken(),//token
    name:
      localGet({
        name: 'name'
      }) || '',
    avatar:
      localGet({
        name: 'avatar'
      }) || '',
    // 角色信息
    roles: localGet({
      name: 'roles'
    }) || {},
    // 路由许可
    permissions:
      localGet({
        name: 'permissions'
      }) || {},
    userId:
      localGet({
        name: 'userId'
      }) || '',
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setStore({
        name: 'token',
        content: state.token,
      })
    },
    SET_NAME: (state, name) => {
      state.name = name
      setStore({
        name: 'name',
        content: state.name,
      })
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
      setStore({
        name: 'avatar',
        content: state.avatar,
      })
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
      setStore({
        name: 'roles',
        content: state.roles,
      })
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
      setStore({
        name: 'permissions',
        content: state.permissions,
      })
    },

    /* id */
    SET_USERID: (state, userId) => {
      state.userId = userId
      setStore({
        name: 'userId',
        content: state.userId,
        type: 'session'
      })
    }
  },
  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.phoneNo
      return new Promise((resolve, reject) => {
        // login({ phoneNo: username, password: userInfo.password })
        login({ phone: username, pwd: userInfo.password })
          .then(response => {
            const data = response.data
            commit('SET_USERID', [])
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        let arr = {
          RoleID: state.userId,
          RouteUrl: "",
          PointTag: "",
        }
        let Encrypta = permissionEncrypt(JSON.stringify(arr));
        loginout(encodeURI(Encrypta))
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_PERMISSIONS', [])
            commit('SET_USERID', '')
            commit('SET_ROLES', {})
            localRemove('SidebarMenu')
            localRemove('tagStates')
            localRemove('permissions')
            localRemove('userId')
            localRemove('avatar')
            localRemove('routerPosition')
            removeToken()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_PERMISSIONS', [])
        commit('SET_USERID', '')
        commit('SET_ROLES', {})
        commit('setSidebarMenu', {})
        commit('setApplication', [])
        localRemove('SidebarMenu')
        localRemove('tagStates')
        localRemove('permissions')
        localRemove('userId')
        localRemove('routerPosition')
        localRemove('avatar')
        localRemove('tags')
        localRemove('trace')
        removeToken()
        resolve()
      })
    },
    // 获取路由
    userGetrouter() {
      return new Promise((resolve, reject) => {
        menu_update().then((res) => {
          resolve(res.data)
        }).catch((err) => {
          // 请求错误
          reject(err)
        })
      })
    },
    // 布控点功能验证
    control_point_verify(state, val) {
      return new Promise((resolve, reject) => {
        control_point_verify(val).then((res) => {
          if (res.data.code == 200) {
            resolve(res.data)
          } else if (res.data.code == 30004) {
            // 未购买
            router.push({
              path: "/tipsPurchase",
              query: {
                goods: permissionEncrypt(JSON.stringify(res.data.data.need_buy_goods)),
                msg: permissionEncrypt(JSON.stringify(res.data.msg)),
              },
            });
            resolve(res.data)
          } else if (res.data.code == 30005) {
            // 无权限
            resolve(res.data)
            errorMessage('您暂无该模块权限,请联系管理员分配权限');
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 路由功能验证
    function_verify(dispatch, val) {
      let verify = (val.function_verify ?? "") !== "" ? val.function_verify : val.roles
      return new Promise((resolve, reject) => {
        function_verify(verify).then((res) => {
          if (res.data.code == 200 && (val.point_verify ?? "") !== "") {
            // 校验布控点是否通过
            if (!res.data.data.is_involved_auth) {
              control_point_verify(val.point_verify).then((res) => {
                if (res.data.code == 200) {
                  resolve(res.data)
                } else if (res.data.code == 30004) {
                  // 未购买
                  router.push({
                    path: "/tipsPurchase",
                    query: {
                      goods: permissionEncrypt(JSON.stringify(res.data.data.need_buy_goods)),
                      msg: permissionEncrypt(JSON.stringify(res.data.msg)),
                    },
                  });
                  resolve(res.data)
                } else {
                  // 无权限
                  resolve(res.data)
                  // errorMessage('您暂无该模块权限,请联系管理员分配权限');
                }
              }).catch((err) => {
                reject(err)
              })
            } else {
              resolve(res.data)
            }
          } else if (res.data.code == 200) {
            resolve(res.data)
          } else if (res.data.code == 30004) {
            // 未购买
            router.push({
              path: "/tipsPurchase",
              query: {
                goods: permissionEncrypt(JSON.stringify(res.data.data.need_buy_goods)),
                msg: permissionEncrypt(JSON.stringify(res.data.msg)),
                // type: res.data.data.type,
              },
            });
            resolve(res.data)
          } else {
            // 无权限
            resolve(res.data)
            // errorMessage('您暂无该模块权限,请联系管理员分配权限');
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 匹配路由权限
    MatchPermissions({ dispatch }, roles) {
      return new Promise((resolve, reject) => {
        let funcModules = null
        let rolesValue = []//权限
        // 获取最新路由  { RouteUrl: roles }
        dispatch('userGetrouter',).then((res) => {
          // let arr = res.data;
          if (res.Code == 200) {
            funcModules = res.Data//用户权限数组
            // 匹配对应的权限
            if (funcModules && funcModules.length > 0) {
              rolesValue = funcModules.filter(item => {
                if (roles.indexOf(item.RouterUrl) != -1 && item.IsUsed == 1) {
                  return item
                }
              })
            }
            // 返回
            resolve(rolesValue)
          } else {
            resolve(rolesValue)
          }
        }).catch((err) => {
          // 请求错误
          reject(err)
        })
      })
    },
  }
}

export default user
