// 本地环境配置
module.exports = {
  title: '业务中台',
  baseUrl: 'http://localhost:8080', // 项目地址
  // baseApi: 'http://192.168.88.169:8080', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  baseApi: 'http://dev.y-cat.com/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  appPrefix: '/auth/api',
  APPID: 'xxx',
  APPSECRET: '/auth/api/v1.0',//新接口前缀
  $cdn: ''
} 