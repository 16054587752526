/**
 * 测试
 */

export default [
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/index.vue'),
    meta: {
      title: '测试模块',
      icon: 'menu-test',
      isMenu: "/test",
      Primary: true,//第一级菜单(判断权限时用)
      keepAlive: true,
    },
  },

  {
    path: '/test/edit/:id',
    name: 'testEdit',
    component: () => import('@/views/test/edit/index.vue'),
    meta: {
      title: '编辑测试模块',
      icon: '',
      keepAlive: true,
      ParentMenu: "/test"//父级菜单路径 显示侧边栏
    }
  },
]