import request from "@/utils/request";
import { APPSECRET, appPrefix } from '@/config'

// 请求路由
export const getrouterUser = (data, params) => {
  return request({
    url: APPSECRET + "/user/getrouter?authrole=" + params,
    method: "post",
    data: data,
  });
};
// 切换产品套餐
export const switch_product = (data) => {
  return request({
    url: APPSECRET + "/web/auth/switch_product",
    method: "post",
    data: data,
  });
};
// 侧边栏菜单更新 
export const menu_update = () => {
  return request({
    url: APPSECRET + "/web/auth/menu_update",
    method: "get",
    // data: data,
  });
};
// 菜单接口校验
export const menu_validation = () => {
  return request({
    url: APPSECRET + "/web/auth/menu_validation",
    method: "get",
    // data: data,
  });
};

// 获取产品订单
export const get_order = () => {
  return request({
    url: APPSECRET + "/web/order/get_order",
    method: "get",
    // data: data,
  });
};

// 登录
export const login = data => {
  return request({
    url: appPrefix + "/web/user/login",
    method: "post",
    data: data
  });
};
// 注册
export const register = data => {
  return request({
    url: appPrefix + "/web/user/register",
    method: "post",
    data: data
  });
};
// 登出
export const loginout = data => {
  return request({
    url: APPSECRET + "/user/loginout?authrole=" + data,
    method: "post",
  });
};
// 修改密码  /api/v1.0/web/user/change_password
export const changepassword = (data) => {
  return request({
    url: APPSECRET + "/web/user/change_password",
    method: "post",
    data: data
  });
};
// 企业信息  
export const getcompanymes = (data) => {
  return request({
    url: APPSECRET + "/web/merchant/get_basic",
    method: "get",
    params: data
  });
};