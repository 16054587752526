

const control = {
  state: {
    deleteJudgment: null,
  },
  mutations: {
    // 判断删除
    setdeleteType(state, items) {
      state.deleteJudgment = items
    },
  },
}

export default control
