// 本地缓存操作 本地存储
export function localGet(key) {
  const value = window.localStorage.getItem('portal_' + key.name)
  try {
    return JSON.parse(window.localStorage.getItem('portal_' + key.name))
  } catch (error) {
    return JSON.parse(value)
  }
}
export function setStore(key) {
  window.localStorage.setItem('portal_' + key.name, JSON.stringify(key.content))
}

export function localRemove(key) {
  window.localStorage.removeItem('portal_' + key)
}