// 正式
module.exports = {
  title: '业务中台',
  // baseUrl: window['APP_ENDPOINT_URL'], // 正式项目地址
  baseUrl: 'https://www.y-cat.com/',// 正式api请求地址
  baseApi: 'https://www.y-cat.com/',// 正式api请求地址
  appPrefix: '/v1.0',//新接口前缀
  APPID: 'xxx',
  // APPSECRET: '/auth/api/v1.0',//新接口前缀
  APPSECRET: '/v1.0',//新接口前缀
  $cdn: ''
}
