/*
 * @Author: your name
 * @Date:2022年6月13日09:48:25
 * @LastEditTime:2022年6月30日15:31:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: src\permission.js
 */
import router from './router'
import store from './store'
import { getToken } from './utils/auth'// getToken from cookie
import { ElLoading } from 'element-plus'
import { setStore, localGet } from '@/utils/localStorage'
import { errorMessage, successMessage } from "@/utils/message.js";
import { menu_validation } from '@/api/login'
import { firstRoute, getRouter } from "@/utils/routerfunction.js";

const whiteList = ['/login', '/404', '/register'] // 免登陆白名单

/* 路由前置守卫 */
router.beforeEach((to, from, next) => {
  // 确定是否有token
  if (getToken()) {
    // 是否跳到商城
    if (disposeShopping(to)) {
      next()//下一个
      setStore({
        name: 'routerPosition',
        content: to.fullPath,
      })
    } else {
      // 是否有菜单 
      let SidebarMenu = localGet({
        name: 'SidebarMenu'
      })
      // let tags = store.getters.layout.tags
      // 没有菜单获取当前用户的权限菜单
      if (SidebarMenu == null || SidebarMenu.length == 0 || SidebarMenu == [] || SidebarMenu == undefined) {
        // 打开加载
        ElLoading.service({ fullscreen: true })
        // 初始化菜单  
        aggregateMenu(to, from, next)
      } else {
        if (to.path == '/login') {
          // 去登陆页
          if (!store.getters.layout.checkDataState) {
            let SidebarMenu = localGet({
              name: 'SidebarMenu'
            })
            next({
              path: RouteMatching(SidebarMenu[0])
            })
          } else {
            next()
          }
        } else if (store.getters.layout.routerType) {
          // 当前标签页自动删除跳转触发
          // routerType 1为删除
          // 第一个删除报错初始化菜单
          if (store.getters.layout.routerType == 1) {
            store.commit('setRouterType', null)
            // 打开加载
            ElLoading.service({ fullscreen: true })
            // 初始化菜单
            store.dispatch("removeTag", to);
            setTimeout(() => {
              aggregateMenu(to, from, next)
            }, 100);
          } if (store.getters.layout.routerType == 2) {
            (async function () {
              next()//下一个
              setTimeout(() => {
                store.commit('setRouterType', null)
              }, 1000);
            }())
          } else {
            (async function () {
              await next()//下一个
              setTimeout(() => {
                store.commit('setRouterType', null)
              }, 1000);
            }()
            )
          }
        } else {
          //路由跳转权限控制           
          let roles = to.path
          // let tags = store.getters.layout.tags
          // 菜单栏点校验菜单权限
          if (Object.keys(to.query) == 0 && !to.query.initialization && SidebarMenu.filter((value) => value.path == roles) && SidebarMenu.filter((value) => value.path == roles).length > 0) {
            // 校验菜单接口
            menu_validation().then((res) => {
              if (res.data.data == true) {
                aggregateVerify(to, from, next)
              } else {
                if (res.data.code == 30003) {
                  aggregateVerify(to, from, next)
                } else if (res.data.code == 30001) {
                  errorMessage('您暂无该模块权限，请联系管理员分配权限');
                  store
                    .dispatch("FedLogOut")
                    .then(() => {
                      setTimeout(() => {
                        location.reload();
                      }, 500);
                    })
                } else if (res.data.code == 30002) {
                  errorMessage('您所在的企业没有可用的应用，请先开通应用后，再使用系统');
                  store
                    .dispatch("FedLogOut")
                    .then(() => {
                      setTimeout(() => {
                        location.reload();
                      }, 500);
                    })
                } else if (res.data.code != 200) {
                  errorMessage(res.data.msg);
                  store
                    .dispatch("FedLogOut")
                    .then(() => {
                      setTimeout(() => {
                        location.reload();
                      }, 500);
                    })
                }
              }
            })
          } else {
            // 匹配对应的路由权限
            aggregateVerify(to, from, next)
          }
        }
      }
    }
  } else {
    /* 没有token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      store.dispatch("FedLogOut"); //清除上一次登陆信息
      next(`/login?redirect=${to.path}`) // 重定向到登录页
    }
  }
})
// 代码部署后被修改页面路由跳转可能-报错处理
router.onError((error, to) => {
  let nextPath = to && (to.path ?? '') !== '' ? to.path : ''
  // 下一个路由
  const targetPath = (nextPath ?? '') !== '' ? nextPath : router.options.history
    .state.current;
  // 赋值刷新
  window.location.href = `/#${targetPath}`
  if (process.env.NODE_ENV !== 'development') {
    setTimeout(() => {
      window.location.reload()
    }, 0)
  }
  // 当捕获到加载模块错误时，重新渲染该目标模块
})
// 校验路由权限方法
function aggregateVerify(to, from, next) {
  // let roles = to.path
  let queryData = {
    roles: to.path,//校验路由
    point_verify: to.query && (to.query.point_verify ?? "") !== "" ? to.query.point_verify : '',
    function_verify: getRouter(to)//point_verify  如详情/编辑/新增跳转
  }
  // 匹配对应的路由权限
  store.dispatch('function_verify', queryData).then(async (res) => {
    // 有权限
    if (res.code == 200 && res.data.is_adopt) {
      // 返回删除当前标签页
      if (Object.keys(to.query) != 0 && to.query.triggerGoBack == 'true') {
        to.query.triggerGoBack = ''
        store.dispatch("removeTag", from);
      }
      await next()//下一个
      // 保存路由
      setStore({
        name: 'routerPosition',
        content: to.fullPath,
      })
      // 登陆时更新判断清除
      setTimeout(() => {
        if (Object.keys(to.query) != 0 && to.query.initialization) {
          router.push({ query: {} });
        }
      }, 300);
    } else if (res.code == 30004) {
      await store.dispatch("removeTag", to);
    } else if (res.code == 30005) {
      // 无权限
      errorMessage(res.msg);
      let tags = store.getters.layout.tags
      if (Object.keys(to.query) != 0 && to.query.triggerGoBack == 'true') {
        to.query.triggerGoBack = ''
        store.dispatch("addTag", from);
      } else {
        // 无标签页刷新菜单栏
        if (!tags || tags.length == 0) {
          // 打开加载
          ElLoading.service({ fullscreen: true })
          // 初始化菜单
          aggregateMenu(to, from, next)
        } else if (to.path == tags[0].path) {
          // 无权限初始化菜单
          // 打开加载
          ElLoading.service({ fullscreen: true })
          // 初始化菜单
          aggregateMenu(to, from, next)
        } else if (from.path == '/' && tags.filter((value) => value.path == to.path).length == 0) {
          // 无权限初始化菜单
          // 打开加载
          ElLoading.service({ fullscreen: true })
          // 初始化菜单
          aggregateMenu(to, from, next)
        }
        if (tags.filter((value) => value.path == to.path).length > 0) {
          await store.dispatch("removeTag", to);
          firstRoute(to, 3);
        }
      }
    }
  }).catch(() => {
  })
}
// 菜单方法
function aggregateMenu(to) {
  store.dispatch('initMenu', router.options.routes).then((res) => {
    // 关闭加载
    setTimeout(() => {
      ElLoading.service().close()
    }, 500);
    // 跳转第一个有权限的
    router.push({
      path: RouteMatching(res[0]),
      query: {
        initialization: true,//初始化判断不校验菜单
      }
    })
    if (Object.keys(to.query) != 0 && to.query.isLogin) {
      successMessage("登录成功");
    }
  }).catch(() => {
    // errorMessage('您暂无该模块权限，请联系管理员分配权限');
    // 没有菜单
    setTimeout(() => {
      ElLoading.service().close()
    }, 800);
    store
      .dispatch("FedLogOut")
      .then(() => {
        setTimeout(() => {
          location.reload();
        }, 300);
      })
  })
}
// 判断是否为商城
function disposeShopping(val) {
  let url = false
  try {
    if (val.path.indexOf('shopping') !== -1) {
      url = true
    }
    //  else if (val.path.indexOf('collectionPage') !== -1) {
    //   url = true
    // } 
    // else if (val.path.indexOf('Entrustment') !== -1) {
    //   url = true
    // } else if (val.path.indexOf('tipsPurchase') !== -1) {
    //   url = true
    // }
    //  else if (val.path.indexOf('historicalTasks') !== -1) {
    //   url = true
    // } 
    else {
      url = false
    }
  } catch (error) {
    url = false
  }
  return url
}
// 处理首次进入加载菜单子项
function RouteMatching(value) {
  let url = ''
  // 有子项
  if (value.children) {
    url = value.children[0].path
  } else {
    url = value.path
  }
  return url
}
router.afterEach(() => {
})
