import { createStore } from 'vuex'
import user from './modules/user'
import control from './modules/control'
import layout from './modules/layout'
import getters from './getters'
let store = createStore({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    layout,
    control
  }
})
// 抛出
export default store
