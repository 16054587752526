/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2022年6月14日09:30:04
 * @LastEditTime: 2022年6月14日09:30:08
 * @LastEditors: Please set LastEditors
 */
const getters = {
  token: state => state.user.token,
  layout: state => state.layout,
  // sidebar: state => state.layout.sidebar,
  SidebarMenu: state => state.layout.SidebarMenu,
  user: state => state.user,
  control: state => state.control,
}
export default getters
