/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2022年6月14日09:28:54
 * @LastEditTime: 2022年6月14日09:29:00
 * @LastEditors: Please set LastEditors
 */
import { setStore, localGet } from '@/utils/localStorage'
// import { menu_validation, } from '@/api/login'
import { errorMessage } from "@/utils/message.js";

const layout = {
  state: {
    // 动态左菜单
    SidebarMenu: localGet({
      name: 'SidebarMenu'
    }) || [],  // 原始菜单项
    tagsView: localGet({
      name: 'tagsView'
    }) || true,
    // 在tagsView中使某些路径不可关闭
    nonCloseablePaths: [], //路径字符串
    // 标记可见
    tags: localGet({
      name: 'tags'
    }) || [], // 标签页路由
    // tags: [], // 标签页路由
    trace: localGet({
      name: 'trace'
    }) || [], // 导航跟踪（路径字符串）
    // 切换topnav上下文（菜单更改）时，应按下/弹出
    tagStates: localGet({
      name: 'tagStates'
    }) || {}, // 保留标记/跟踪状态（支持topnav changes菜单保留）
    // ==========侧边栏===============
    // 侧边栏控制
    sidebar: {
      opened: localStorage.middle_sidebarStatus ? localStorage.middle_sidebarStatus === 'true' : true,
      withoutAnimation: false
    },
    checkparameter: 0,
    checkDataState: null,//校验数据状态 1退出 2菜单切换
    routerType: null,//控制路由状态
    // keepalive缓存
    cachedViews: localGet({
      name: 'tags'
    }) ? localGet({
      name: 'tags'
    }).filter(i => i.meta && i.meta.keepAlive).map(l => l.name) : [],
    // 顶部应用数据
    ApplicationGroup: localGet({
      name: 'ApplicationGroup'
    }) || [],
  },
  mutations: {
    // 有数据修改
    setCheckDataState(state, items) {
      state.checkDataState = items
    },
    setRouterType(state, items) {
      state.routerType = items
    },
    setCheckparameter(state, items) {
      state.checkparameter = items
    },
    // 设置顶部应用
    setApplication(state, items) {
      state.ApplicationGroup = items
      setStore({
        name: 'ApplicationGroup',
        content: state.ApplicationGroup,
      })
    },
    setSidebarMenu(state, items) {
      state.SidebarMenu = items
      setStore({
        name: 'SidebarMenu',
        content: `${items}`,
      })
    },
    setTagsView(state, value) {
      state.tagsView = value
      setStore({
        name: 'tagsView',
        content: `${value}`,
      })
    },
    setNonCloseablePaths(state, value) {
      state.nonCloseablePaths = value
    },
    setTags(state, value) {
      state.tags = value
      let tags = []
      if (state.tags && state.tags.length > 0) {
        // state.tags.forEach(item => {
        //   tags.push(item)
        // })
        tags = state.tags.map(item => {
          return {
            path: item.path,
            fullPath: item.fullPath,
            meta: item.meta,
            name: item.name,
            params: item.params,
            query: item.query,
          }
        })
      }
      var cache = [];
      var str = JSON.stringify(tags, function (key, value) {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // Circular reference found, discard key
            return;
          }
          // Store value in our collection
          cache.push(value);
        }
        return value;
      });
      cache = null;
      window.localStorage.setItem('portal_tags', str)
    },
    setCacheViews(state, value) {
      state.cachedViews = value
    },
    appendTrace(state, value) {
      let tags = value.map(item => {
        return item.fullPath
      })
      state.trace = [...new Set(tags)]//去重
      setStore({
        name: 'trace',
        content: state.trace,
      })
    },
    removeTraces(state, value) {
      state.trace = state.trace.filter(t => t !== value)
      setStore({
        name: 'trace',
        content: state.trace,
      })
    },
    setTrace(state, value) {
      state.trace = value
    },
    pushTagState(state) {
      const tags = state.tags.map(item => {
        return {
          path: item.path,
          fullPath: item.fullPath,
          meta: item.meta,
          name: item.name,
          params: item.params
        }
      })
      state.tagStates = {
        tags: tags,
        trace: state.trace
      }
      setStore({
        name: 'tagStates',
        content: state.tagStates,
      })
    },
    popTagState(state,) {
      if (state.tagStates) {
        const { tags, trace } = state.tagStates
        state.tags = tags
        state.trace = trace
      } else {
        state.tags = []
        state.trace = []
      }
    },
    // type = 0 (set open=false), 1 (toggle true/false)
    TOGGLE_SIDEBAR: (state, type) => {
      state.sidebar.withoutAnimation = false
      if (type === 0) {
        state.sidebar.opened = false
      } else if (type === 1) {
        state.sidebar.opened = true
      } else {
        state.sidebar.opened = !state.sidebar.opened
      }
      setStore({
        name: 'sidebarStatus',
        content: `${state.sidebar.opened}`,
      })
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
      setStore({
        name: 'sidebarStatus',
        content: `${state.sidebar.opened}`,
      })
    },
    // 清除所有tags、trace、tagStates
    clearAllTags(state) {
      state.tags = []
      state.trace = []
      state.tagStates = {}
    }
  },
  getters: {
    tagsView: (state) => state.tagsView,
    // last navigation result from trace history
    lastPath: (state) => state.trace.length > 0 ? state.trace[state.trace.length - 1] : null,
    sidebar: (state) => state.sidebar
  },
  actions: {
    getcachedViews() { },
    // save state, call handler, and navigate to the url
    clickTopNav({ commit }, itemId) {
      commit('setTopNav', itemId)
      commit('popTagState', itemId)
    },

    toggleSideBar({ commit }, type) {
      commit('TOGGLE_SIDEBAR', type)
    },
    closeSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },

    addTag({ getters, commit, state }, tag) {
      // check duplicate
      if (!state.tags.map(r => r.path).includes(tag.path)) {
        const index = state.tags.findIndex(r => r.path === getters.lastPath)
        const tags = [...state.tags]
        index > -1 ? tags.splice(index + 1, 0, tag) : tags.push(tag)
        commit('setTags', tags)
        commit('appendTrace', tags)
        commit('setCacheViews', state.tags.filter(i => i.meta && i.meta.keepAlive).map(l => l.name))
        commit('pushTagState', state.topNavSelected)
      }
      // commit('appendTrace', tag.fullPath)
    },
    // return the next route after delete this tag
    removeTag({ commit, state, getters }, tag) {
      commit('setTags', state.tags.filter(r => r.path !== tag.path))
      commit('setCacheViews', state.tags.filter(i => i.meta && i.meta.keepAlive).map(l => l.name))
      commit('removeTraces', tag.fullPath)
      commit('appendTrace', state.tags)
      return getters.lastPath
    },
    removeTags({ commit, state, getters }, tags) {
      const paths = tags.map(t => t.path)
      commit('setTags', state.tags.filter(r => !paths.includes(r.path)))
      commit('setCacheViews', state.tags.filter(i => i.meta && i.meta.keepAlive).map(l => l.name))
      commit('setTrace', state.trace.filter(t => !paths.includes(t)))
      return getters.lastPath
    },
    refreshMenu({ commit, state }) {
      commit('setSidebarMenu', state.SidebarMenu)
    },
    // 初始化菜单
    async ['initMenu'](store, config) {
      const { dispatch, commit } = store
      // console.log(rootState.user) // 打印其他模块的 state
      try {
        return new Promise((resolve, reject) => {
          let route = []//路由
          let SidebarMenu = []//菜单显示
          let permissions = []//角色信息
          let functionArr = []
          let function_by = []
          // 权限白名单
          // let whiteList = [
          //   "/shopping"
          // ]
          // 取出首页菜单第一级
          config.forEach((value) => {
            if (value.path == "/Layout") {
              route = value.children.filter(it => it.meta.Primary == true)
            }
          })
          // 拷贝
          functionArr = JSON.parse(JSON.stringify(route))
          // 侧边栏更新接口
          dispatch('userGetrouter', '').then((res) => {
            if (res.code == 200) {
              permissions = res.data
              // 判断不是功能模块的路由
              if (permissions.function && permissions.function.length > 0) {
                permissions.function.forEach(value => {
                  functionArr.forEach((item, index) => {
                    if (value.resources_routing == item.meta.isMenu) {
                      functionArr.splice(index, 1)
                    }
                  })
                })
              }
              // 判断被角色引用的路由
              if (permissions.function_by_product) {
                route.forEach((item) => {
                  permissions.function_by_product.forEach((value) => {
                    // 判断子级权限
                    if (item.children && item.children.length != 0) {
                      let arr = JSON.parse(JSON.stringify(item.children))
                      let num = []
                      permissions.function_by_product.forEach(T => arr.filter(it => {
                        if (T.resources_routing == it.meta.isMenu) {
                          num.push(it)
                        }
                      }))
                      // 判断全部子级是否有权限
                      if (num && num.length > 0) {
                        item.children = num
                        function_by.push(item)
                      }
                    } else {
                      if (value.resources_routing == item.meta.isMenu) {
                        function_by.push(item)
                      }
                    }
                  })
                })
              }
              // 判断权限合并
              SidebarMenu = [...function_by, ...functionArr]
              // 设置
              // store.commit("SET_PERMISSIONS", permissions);
              // 过滤白名单
              // permissions = permissions.filter(item => {
              //   return !whiteList.find(prop => {
              //     return prop == item.RouterUrl
              //   })
              // })
              // 过滤重复菜单
              const obj = {}
              SidebarMenu = SidebarMenu.reduce((total, next) => {
                obj[next.meta.title] ? '' : obj[next.meta.title] = true && total.push(next)
                return total
              }, [])
              //设置菜单
              commit('setSidebarMenu', SidebarMenu)
              setStore({
                name: 'SidebarMenu',
                content: SidebarMenu,
              })
              // config.forEach((value) => {
              //   if (value.path == "/Layout") {
              //     value.children.push(...SidebarMenu)
              //   }
              // })
              // 过滤菜单
              store.dispatch('FirstRoute').then((res) => {
                // 返回
                resolve(res)
              }).catch((err) => {
                reject(err)
              })
            } else {
              if (res.code == 10000) {
                errorMessage(res.msg);
                store.dispatch("FedLogOut").then(() => {
                  setTimeout(() => {
                    location.reload(); //刷新
                  }, 800);
                });
                // reject(res)
              } else {
                errorMessage(res.msg);
                store.dispatch("FedLogOut").then(() => {
                  setTimeout(() => {
                    location.reload(); //刷新
                  }, 800);
                });
                // reject(res)
              }
            }
          }).catch((err) => {
            // 请求错误
            reject(err)
          })
        })
      } catch (error) { console.log(error) }
    },
    // 第一个路由 FilterValue需要过滤的路由
    async ['FirstRoute'](store, FilterValue = '') {
      // const { rootState } = store
      // 过滤白名单
      let whiteList = [
        "/shopping"
      ]
      // 追加白名单
      if (FilterValue != '') {
        whiteList.push(...FilterValue)
      }
      return new Promise((resolve, reject) => {
        (async function () {
          // 获取菜单 
          let SidebarMenu = localGet({
            name: 'SidebarMenu'
          })
          let nextRoles = []//下一个
          let verify = false
          for (let index = 0; index < SidebarMenu.length; index++) {
            let queryData = {
              roles: SidebarMenu[index].path,//校验路由
            }
            await store.dispatch('function_verify', queryData).then((res) => {
              if (index == SidebarMenu.length - 1) {
                // 最后一个菜单项判断没有权限则退出并报错
                if (res.code == 200) {
                  if (res.data.is_adopt) {
                    verify = true
                  } else {
                    // errorMessage('账号无可用菜单');
                    errorMessage('您暂无该模块权限,请联系管理员分配权限');
                    store.dispatch("FedLogOut").then(() => {
                      setTimeout(() => {
                        location.reload(); //刷新
                      }, 800);
                    });
                  }
                } else {
                  // errorMessage('账号无可用菜单');
                  errorMessage('您暂无该模块权限,请联系管理员分配权限');
                  store.dispatch("FedLogOut").then(() => {
                    setTimeout(() => {
                      location.reload(); //刷新
                    }, 800);
                  });
                }

              } else {
                if (res.code == 200) {
                  if (res.data.is_adopt) {
                    verify = true
                  }
                }
              }
            })
            // 有权限跳出循环请求
            if (verify) {
              nextRoles.push(SidebarMenu[index])
              break
            }
          }
          // 匹配存在权限路由
          if (verify) {
            // 判断
            if (nextRoles && nextRoles.length > 0) {
              resolve(nextRoles)
            } else {
              reject(nextRoles)
            }
          } else {
            reject(nextRoles)
          }
        }())

      })
    },
  }
}

export default layout
