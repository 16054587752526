// 请求加密处理
import CryptoJS from "crypto-js";
import store from '../store'

export function s4(num) {
  // const num = Math.random();
  return num ? num.toString(36).slice(-4) : s4();
}

export function slice16(val) {
  return s4(val) + s4(val) + s4(val) + s4(val)
}
// const key = s16();
// const iv = s16();

function parse(str) {
  return CryptoJS.enc.Utf8.parse(str);
}

//解密方法
export function Decrypt(code, secret_key, iv) {
  return CryptoJS.AES.decrypt(code, parse(secret_key), {
    iv: parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString(CryptoJS.enc.Utf8);
}

//加密方法
export function Encrypt(data, secret_key, iv) {
  return CryptoJS.AES.encrypt(parse(data), parse(secret_key), {
    iv: parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
}

//加密方法权限校验
export function permissionEncrypt(data,) {
  let secret_key = store.getters.user.token.slice(0, 16);
  return Encrypt(data, secret_key, secret_key)
}
export function routerDecrypt(data) {
  let secret_key = store.getters.user.token.slice(0, 16);
  return Decrypt(data, secret_key, secret_key)
}