/**
 * 账号管理
 */

export default [
  {
    path: '/account',
    name: 'account1',
    component: () => import('@/views/accountRole/index.vue'),
    meta: {
      title: '账号管理',
      icon: 'menu-account',
      isMenu: "/account",
      keepAlive: true,
      Primary: true,//第一级菜单(判断权限时用)
    },
  },
  {
    path: '/account/role/add',
    name: 'addRole',
    component: () => import('@/views/accountRole/role/add.vue'),
    meta: {
      title: '新增角色',
      icon: '',
      keepAlive: true,
      ParentMenu: "/account",//父级菜单路径 显示侧边栏
    }
  },
  {
    path: '/account/role/edit/:id',
    name: 'editRole',
    component: () => import('@/views/accountRole/role/edit.vue'),
    meta: {
      title: '编辑角色',
      icon: '',
      keepAlive: true,
      ParentMenu: "/account",//父级菜单路径 显示侧边栏
    }
  },
]