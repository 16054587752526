/**
 * 商城
 */
export default [
  {
    path: '/shopping/home',
    name: 'shoppinghome',
    props: true,
    component: () => import('@/views/shopping/shoppingHome/index.vue')
    ,
    meta: {
      title: '商城首页',
      icon: 'clue',
      // affix: true,//标签页不能关闭
      // isMenu判断菜单和标签页显示 在侧边栏及标签组件
      isMenu: "/shopping",
      keepAlive: true
    }
  },
  {
    path: '/shopping/order',
    name: 'shoppingOrder',
    props: true,
    component: () => import('@/views/shopping/order/index.vue')
    ,
    meta: {
      title: '我的订单',
      icon: 'clue',
      // affix: true,//标签页不能关闭
      // isMenu判断菜单和标签页显示 在侧边栏及标签组件
      isMenu: "order",
      keepAlive: true
    }
  },
  {
    path: '/shopping/detailPage',
    name: 'shoppingdetailPage',
    props: true,
    component: () => import('@/views/shopping/order/detailPage.vue')
    ,
    meta: {
      title: '我的订单',
      icon: 'clue',
      // affix: true,//标签页不能关闭
      // isMenu判断菜单和标签页显示 在侧边栏及标签组件
      isMenu: "detailPage",
      keepAlive: true
    }
  },
  {
    path: '/shopping/payment',
    name: 'shoppingpayment',
    props: true,
    component: () => import('@/views/shopping/payment/index.vue')
    ,
    meta: {
      title: '订单支付',
      icon: 'clue',
      // affix: true,//标签页不能关闭
      // isMenu判断菜单和标签页显示 在侧边栏及标签组件
      isMenu: "payment",
      keepAlive: true
    }
  },
]