import request from "@/utils/request";
import { APPSECRET } from '@/config'

// 校验布控点 
export const control_point_verify = (data) => {
  return request({
    url: APPSECRET + "/web/auth/control_point_verify",
    method: "get",
    point_verify: true,
    pointValue: data,
  });
};

// 路由功能校验
export const function_verify = (data) => {
  return request({
    url: APPSECRET + "/web/auth/function_verify",
    method: "get",
    isfunction_verify: true,
    functionValue: data,
  });
};