import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 样式
import '@/icons'//icons
import '@/style/index.scss' // global css
import '@/style/scrollbar-style.scss' // 滚动条样式
import '@/assets/font/font.css';

// js
import '@/permission' // permission control
// 插件
import SvgIcon from "@/components/SvgIcon"
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
// require('./mock')

// 超出高度显示弹窗指令
import { ellipsisDirective } from "@/directives/ellipsis";
// 挂载
const app = createApp(App)
// app.component("", );
app.component("svg-icon", SvgIcon)
  .use(store).use(router).directive('ellipsis', ellipsisDirective).use(ElementPlus, { locale: zhCn, }).mount('#app')
