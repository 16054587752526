<template>
  <div class="box" @click="close">
    <svg-icon
      class="message-icon"
      style="display: flex"
      :iconClass="`check-message`"
    ></svg-icon>
  </div>
</template>

<script  >
import { checkClose } from "@/utils/message.js";
import { reactive, toRefs } from "vue";
export default {
  setup() {
    // 数据
    let data = reactive({
      close() {
        // 调用关闭
        checkClose();
      },
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style lang='scss' scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-icon {
  display: flex;
  width: 32px;
  height: 32px;
  // background: #ef608f;
}
</style>
