<template>
  <div class=""></div>
</template>

<script  >
</script>

<style lang='scss' scoped>
.message-icon {
  width: 36px;
  height: 36px;
  display: flex;
}
</style>
