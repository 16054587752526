import { createRouter, createWebHistory } from 'vue-router'
// 模块引入
import shopping from './modular/shopping'
import account from './modular/account'
import invite from './modular/invite'
import test from './modular/test'
// import Entrustment from './modular/Entrustment'
// import collectionPage from './modular/collectionPage'
// import historicalTasks from './modular/historicalTasks'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register/index.vue')
  },
  //路由重定向
  {
    path: '',
    redirect: '/login',
  },
  {
    path: '/shopping',
    name: 'shopping',
    component: () => import('@/views/shopping/index.vue'),
    hidden: false,
    redirect: '/shopping/home',
    children: [
      ...shopping
    ]
  },
  {
    path: '/Layout',
    name: 'Layout',
    component: () => import('@/views/layout/Layout.vue'),
    hidden: false,
    children: [
      ...test,
      // ...Entrustment,
      // ...collectionPage,
      // ...historicalTasks,
      ...invite,
      ...account,
      {
        path: '/tipsPurchase',
        name: 'tipsPurchase',
        component: () => import('@/views/tipsPurchase/index.vue'),
        meta: {
          title: '购买提示',
        },
      },

    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

export default router
